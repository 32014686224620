<!-- 高级搜索区域 -->
<template>
  <div>
    <a-form ref="searchForm" :model="searchParam">
      <a-row>
        <a-col :span="6">
          <a-form-item
            label="公司:"
            name="unit"
            :labelCol="formLayout2X2.labelCol"
            :wrapperCol="formLayout2X2.wrapperCol"
          >
            <a-input
              v-model:value="searchParam.unit"
              type="text"
              placeholder="请输入公司名称"
            />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item
            label="行业:"
            name="profession"
            :labelCol="formLayout2X2.labelCol"
            :wrapperCol="formLayout2X2.wrapperCol"
          >
            <a-input
              v-model:value="searchParam.profession"
              type="text"
              placeholder="请输入所属行业"
            />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-form-item
            label="部门:"
            name="depart"
            :labelCol="formLayout2X2.labelCol"
            :wrapperCol="formLayout2X2.wrapperCol"
          >
            <a-input
              v-model:value="searchParam.depart"
              type="text"
              placeholder="请输入所属部门"
            />
          </a-form-item>
        </a-col>
        <a-col :span="6">
          <a-row>
            <a-col :span="18">
              <a-form-item
                label="状态:"
                name="status"
                :labelCol="formLayout2X2.labelCol"
                :wrapperCol="formLayout2X2.wrapperCol"
              >
                <a-radio-group v-model:value="searchParam.status">
                  <a-radio value="1">正常</a-radio>
                  <a-radio value="0">禁用</a-radio>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <a-col :span="4">
              <a-button type="primary" @click="doSearch"
                ><SearchOutlined />搜索</a-button
              >
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>

<script lang='js'>
import { SearchOutlined } from "@ant-design/icons-vue";
import { reactive,toRefs,defineComponent,ref} from 'vue'
export default defineComponent({
  name: 'SearchArea',
  components: {
    SearchOutlined
  },
  setup(props,context) {
    const searchForm = ref();
    const state = reactive({
      searchParam:{
        mode:"A",
        unit:"",
        profession:"",
        depart:"",
        status:1,
      },
      formLayout2X2:{
        labelCol: {
              span: 5,
            },
            wrapperCol: {
              span: 19,
            },
      }
    })
    const doSearch = () =>{
      context.emit("initUserList",state.searchParam);
    }
    const refData = toRefs(state);
    return {
        ...refData,
        searchForm,
        doSearch,
    }
  }
  });
</script>
<style lang='scss' scoped>
.ant-form-item {
  margin-bottom: 0px;
}
</style>